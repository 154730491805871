import { React } from 'react';

import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '../components/protectedRoutes';
import NotificarCliente from '../pages/comercial/notificarCliente';
import UserFakePortal from '../pages/comercial/userFakePortal';
import AddAdditiveCC from '../pages/gestaoEnergia/addAdditiveCC';
import AdicionarCC from '../pages/gestaoEnergia/adicionarCC';
import AluguelMataraca from '../pages/gestaoEnergia/aluguelMataraca';
import AtualizarSiteDoacao from '../pages/gestaoEnergia/atualizarSiteDoacao';
import CadastrarCC from '../pages/gestaoEnergia/cadastrarCC';
import CalcularAluguel from '../pages/gestaoEnergia/calcularAluguel';
import Distrato from '../pages/gestaoEnergia/distrato';
import EmailTrocaTitularidade from '../pages/gestaoEnergia/emailTrocaTitularidade';
import GerarCSVFaturamento from '../pages/gestaoEnergia/gerarCSVFaturamento';
import GerarFechamento from '../pages/gestaoEnergia/gerarFechamento';
import ListarCCsConsorcio from '../pages/gestaoEnergia/listarCCsConsorcio';
import ListarDebitos from '../pages/gestaoEnergia/listarDebitos';

const RouteGE = () => {
    return (
        <Routes>
            <Route element={<ProtectedRoute role='GE'/>}>
                <Route path='addAdditive' element={<AddAdditiveCC/>}/>
                <Route path='adicionarCC' element={<AdicionarCC/>}/>
                <Route path='cadastrarCC' element={<CadastrarCC/>}/>
                <Route path='aluguelMataraca' element={<AluguelMataraca/>}/>
                <Route path='atualizarSiteDoacao' element={<AtualizarSiteDoacao/>}/>
                <Route path='gerarFechamento' element={<GerarFechamento/>}/>
                <Route path='gerarCSVFaturamento' element={<GerarCSVFaturamento/>}/>
                <Route path='calcularAluguel' element={<CalcularAluguel/>}/>
                <Route path='distrato' element={<Distrato/>}/>
                <Route path='emailTrocaTitularidade' element={<EmailTrocaTitularidade/>}/>
                <Route path='notificarCliente' element={<NotificarCliente/>}/>
                <Route path='userFakePortal' element={<UserFakePortal/>}/>
                <Route path='listarCCsConsorcio' element={<ListarCCsConsorcio/>}/>
                <Route path='listarDebitos' element={<ListarDebitos/>}/>
            </Route>
        </Routes>
    );
};

export default RouteGE;
