import { React, useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Grid, Paper } from '@mui/material';

import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';
import { fetchFlask } from '../../utils/fetchFlask';
import { maskCpfCnpj } from '../../utils/maskCpfCnpj';

const AddAdditiveCC = () => {
    const [listaClientes, setListaClientes] = useState([]);
    const [listaUnidades, setListaUnidades] = useState([]);
    const [cliente, setCliente] = useState('');
    const [cc, setCC] = useState('');
    const [unidade, setUnidade] = useState('');
    const [nomeUnidade, setNomeUnidade] = useState('');
    const [contaEnergia, setContaEnergia] = useState(null);
    const [aditivo, setAditivo] = useState(null);
    const [ddd, setDDD] = useState('');
    const [ddd2, setDDDDois] = useState('');
    const [telefone, setTelefone] = useState('');
    const [telefoneDois, setTelefoneDois] = useState('');
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [emailDois, setEmailDois] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [consumoMedio, setConsumoMedio] = useState('');
    const [distribuidora, setDistribuidora] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const contaRef = useRef(null);

    useEffect(() => {
        async function getClients () {
            const response = await fetchFlask('getClientes');
            if (response.status === 'error') {
                handleAlerta(response);
            } else {
                const clientsArray = response.dados.map(obj => {
                    const key = Object.keys(obj)[0];
                    const value = obj[key];
                    return { key, value };
                });
                setListaClientes(clientsArray);
            }
        }
        getClients();
    }, []);

    const handleClientChange = async (e) => {
        const selectedClient = e.target.value;
        setCliente(selectedClient);

        const body = JSON.stringify({ client: selectedClient });
        const response = await fetchFlask('getClientDataAditivo', body);

        if (response.status !== 'error') {
            if (response.dados.length === 1) {
                const unitData = response.dados[0];
                setNomeUnidade(unitData.unit);
                setNome(unitData.name);
                setDDD(unitData.ddd);
                setTelefone(unitData.phone);
                if (unitData.phone2) {
                    setDDDDois(unitData.ddd2);
                    setTelefoneDois(unitData.phone2);
                };
                setEmail(unitData.email);
                setEmailDois(unitData.email2);
                setCpfCnpj(unitData.cpfCnpj);
            } else {
                setListaUnidades(response.dados);
                setNomeUnidade('');
                setNome('');
                setDDD('');
                setTelefone('');
                setDDDDois('');
                setTelefoneDois('');
                setEmail('');
                setEmailDois('');
                setCpfCnpj('');
            };
        } else {
            handleAlerta(response);
        }
    };

    const handleUnitChange = async (e) => {
        console.log(e);
        const unitData = listaUnidades.find(unit => unit.unit === e.target.value);
        setNomeUnidade(unitData.unit);
        setNome(unitData.name);
        setDDD(unitData.ddd);
        setTelefone(unitData.phone);
        if (unitData.phone2) {
            setDDDDois(unitData.ddd2);
            setTelefoneDois(unitData.phone2);
        };
        setEmail(unitData.email);
        setEmailDois(unitData.email2);
        setCpfCnpj(unitData.cpfCnpj);
    };

    async function submitAdicionarCC (e) {
        e.preventDefault();
        setIsSubmit(true);

        const formData = new FormData();
        formData.append('cliente', cliente);
        formData.append('cc', cc);
        formData.append('unidade', unidade);
        formData.append('ddd', ddd);
        formData.append('telefone', telefone);
        formData.append('telefoneDois', telefoneDois);
        formData.append('nome', nome);
        formData.append('email', email);
        formData.append('emailDois', emailDois);
        formData.append('cpfCnpj', cpfCnpj);
        formData.append('consumoMedio', consumoMedio);
        formData.append('distribuidora', distribuidora);
        formData.append('contaEnergia', contaEnergia);

        const response = await fetchFlask('addCC', formData);

        handleAlerta(response);
        if (response.status === 'success') {
            setCliente('');
            setCC('');
            setUnidade('');
            setNome('');
            setDDD('');
            setTelefone('');
            setTelefoneDois('');
            setEmail('');
            setEmailDois('');
            setCpfCnpj('');
            setConsumoMedio('');
            setDistribuidora('');
            setContaEnergia(null);
            setAditivo(null);
            contaRef.current.value = '';
        }
        setIsSubmit(false);
    }

    async function submitAditivo (e) {
        e.preventDefault();
        setIsSubmit(true);

        const formData = new FormData();
        formData.append('cliente', cliente);
        formData.append('cpfCnpj', cpfCnpj);
        formData.append('aditivo', aditivo);

        const response = await fetchFlask('addAdditive', formData);

        handleAlerta(response);
        if (response.status === 'success') {
            setCliente('');
            setCpfCnpj('');
            setAditivo(null);
            aditivo.current.value = '';
        }
        setIsSubmit(false);
    }

    function handleAlerta (response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                Aditivo
            </Typography>

            <form action='' method='POST' id='formAditivo' onSubmit={submitAditivo}>
                <Grid container>
                    <Grid item xs={5}>
                        <FormControl sx={{ display: 'flex', marginTop: '20px' }} >
                            <InputLabel id='labelCliente'>Cliente</InputLabel>
                            <Select
                                id='selectCliente'
                                labelId='labelCliente'
                                label='Cliente'
                                value={cliente}
                                onChange={handleClientChange}
                                required
                            >
                                {listaClientes.map((item) => (
                                    <MenuItem key={item.value} value={item.key}>{item.key}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={8}>
                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                                <TextField
                                    required
                                    label='CPF ou CNPJ'
                                    value={cpfCnpj}
                                    onChange={(e) => setCpfCnpj(maskCpfCnpj(e.target.value))}
                                />
                        </FormControl>
                    </Grid>

                    <Grid item xs={8}>
                        <FormControl sx={{ display: 'block', marginTop: '30px' }}>
                            <TextField
                                type='file'
                                size='small'
                                inputRef={aditivo}
                                inputProps={{ accept: 'application/pdf, image/*' }}
                                onChange={(e) => setAditivo(e.target.files[0])}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl sx={{ display: 'block', marginTop: '30px' }}>
                            <SubmitButton isSubmit={isSubmit}>Adicionar Aditivo</SubmitButton>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}></Grid>

                    <Grid item xs={5}>
                        <FormControl sx={{ display: 'block', marginTop: '30px' }}>
                            <Typography variant="body1">Adicionar CC</Typography>
                            <Switch
                                checked={showForm}
                                onChange={(e) => setShowForm(e.target.checked)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>

            {showForm && (
                <form action='' method='POST' id='formCadastrarCC' onSubmit={submitAdicionarCC}>
                    <Grid container spacing={1}>

                        <Grid item xs={2}>
                            <FormControl sx={{ display: 'flex', marginTop: '20px' }}>
                                <TextField
                                    required
                                    label='Conta Contrato'
                                    value={cc}
                                    onChange={(e) => setCC(e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                            <FormControl sx={{ display: 'flex', marginTop: '20px' }}>
                                <TextField
                                    required
                                    label='Unidade'
                                    value={unidade}
                                    onChange={(e) => setUnidade(e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl sx={{ display: 'flex', marginTop: '20px' }}>
                                <InputLabel id='labelDistribuidora'>Distribuidora</InputLabel>
                                <Select
                                    id='selectDistribuidora'
                                    labelId='labelDistribuidora'
                                    label='Distribuidora'
                                    value={distribuidora}
                                    onChange={(e) => setDistribuidora(e.target.value)}
                                    sx={{ width: '220px' }}
                                    required
                                >
                                    <MenuItem value={'COSERN'}>COSERN</MenuItem>
                                    <MenuItem value={'ENERGISA PB'}>ENERGISA PB</MenuItem>
                                    <MenuItem value={'NEOENERGIA PE'}>NEOENERGIA PE</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                                <Typography>Conta de Energia</Typography>
                                <TextField
                                    type='file'
                                    size='small'
                                    inputRef={contaRef}
                                    inputProps={{ accept: 'application/pdf, image/*' }}
                                    onChange={(e) => setContaEnergia(e.target.files[0])}
                                />
                            </FormControl>
                        </Grid>

                    </Grid>

                    <Grid container>
                        <Grid item xs={9}>
                            <Paper elevation={2} sx={{ padding: 2, marginTop: '40px' }}>
                                <Typography variant='h5'>
                                    Contato
                                </Typography>
                                <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                    {cliente && listaUnidades.length > 1 &&
                                        <>
                                            <Grid item xs={9}>
                                                <FormControl sx={{ display: 'flex', marginTop: '20px' }}>
                                                    <InputLabel id='labelUnit'>Unidade</InputLabel>
                                                    <Select
                                                        id='selectUnit'
                                                        labelId='labelUnit'
                                                        label='Unit'
                                                        value={nomeUnidade}
                                                        onChange={handleUnitChange}
                                                        required
                                                    >
                                                        {listaUnidades.map((item) => (
                                                            <MenuItem key={item.unit} value={item.unit}>
                                                                {item.unit}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </>
                                    }

                                    <Grid item xs={4}>
                                        <FormControl sx={{ display: 'flex', marginTop: '20px' }}>
                                            <TextField
                                                required
                                                label='Nome'
                                                value={nome}
                                                onChange={(e) => setNome(e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <FormControl sx={{ display: 'flex', marginTop: '20px' }}>
                                            <TextField
                                                required
                                                label='CPF ou CNPJ'
                                                value={cpfCnpj}
                                                onChange={(e) => setCpfCnpj(maskCpfCnpj(e.target.value))}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <FormControl sx={{ display: 'flex', marginTop: '20px' }}>
                                            <TextField
                                                required
                                                label='Consumo Médio'
                                                value={consumoMedio}
                                                onChange={(e) => setConsumoMedio(e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={3}></Grid>

                                    <Grid item xs={2}>
                                        <FormControl sx={{ display: 'flex', marginTop: '20px' }}>
                                            <TextField
                                                required
                                                label='DDD'
                                                value={ddd}
                                                onChange={(e) => setDDD(e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <FormControl sx={{ display: 'flex', marginTop: '20px' }}>
                                            <TextField
                                                required
                                                label='Telefone'
                                                value={telefone}
                                                onChange={(e) => setTelefone(e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={3}></Grid>

                                    <Grid item xs={3}></Grid>

                                    <Grid item xs={2}>
                                        <FormControl sx={{ display: 'flex', marginTop: '20px' }}>
                                            <TextField
                                                label='DDD 2'
                                                value={ddd2}
                                                onChange={(e) => setDDDDois(e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <FormControl sx={{ display: 'flex', marginTop: '20px' }}>
                                            <TextField
                                                label='Telefone 2'
                                                value={telefoneDois}
                                                onChange={(e) => setTelefoneDois(e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={3}></Grid>

                                    <Grid item xs={5}>
                                        <FormControl sx={{ display: 'flex', marginTop: '20px' }}>
                                            <TextField
                                                required
                                                label='Email'
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={5}>
                                        <FormControl sx={{ display: 'flex', marginTop: '20px' }}>
                                            <TextField
                                                label='Email 2'
                                                value={emailDois}
                                                onChange={(e) => setEmailDois(e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>

                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                        <Grid item xs={4}>
                            <FormControl sx={{ display: 'block', marginTop: '40px' }}>
                                <SubmitButton isSubmit={isSubmit}>Adicionar CC</SubmitButton>
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Box>
    );
};

export default AddAdditiveCC;
