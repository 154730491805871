import { React, useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Grid, IconButton, Paper, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { pt } from 'date-fns/locale';

import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';
import { fetchFlask } from '../../utils/fetchFlask';
import { maskCpfCnpj } from '../../utils/maskCpfCnpj';

const GenerateAdditive = () => {
    const [listaClientes, setListaClientes] = useState([]);
    const [client, setClient] = useState('');
    const [clientType, setClientType] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [representativeName, setRepresentativeName] = useState('');
    const [representativeCpf, setRepresentativeCpf] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [discount, setDiscount] = useState('');
    const [signDate, setSignDate] = useState(null);
    const [rows, setRows] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [cc, setCC] = useState('');
    const [ccAddress, setCCAddress] = useState('');
    const [consumption, setConsumption] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [errorSignDate, setErrorSignDate] = useState(false);

    const columns = [
        {
            field: 'ccCol',
            headerName: 'CC',
            width: 150,
            editable: true
        },
        {
            field: 'addressCol',
            headerName: 'Endereço',
            width: 150,
            editable: true
        },
        {
            field: 'consumptionCol',
            headerName: 'Consumo',
            type: 'number',
            width: 110,
            editable: true
        },
        {
            field: ' ',
            renderCell: (params) => (
                <IconButton edge="end" onClick={() => deleteCC(params.row.id)}>
                    <DeleteIcon />
                </IconButton>
            )
        }
    ];

    const handleRowSelection = (newSelection) => {
        setSelectedRows(newSelection);
    };

    const deleteCC = (id) => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    };

    const processRowUpdate = (newRow) => {
        setRows((prevRows) =>
            prevRows.map((row) => (row.id === newRow.id ? newRow : row))
        );
        return newRow;
    };

    const addUnits = () => {
        if (!cc || !ccAddress || !consumption) {
            alert('Preencha todos os campos para adicionar uma unidade!');
            return;
        }

        setRows([...rows, { id: rows.length + 1, ccCol: cc, addressCol: ccAddress, consumptionCol: consumption }]);
        setCC('');
        setCCAddress('');
        setConsumption('');
    };

    useEffect(() => {
        async function getClients() {
            const response = await fetchFlask('getClientes');
            if (response.status === 'error') {
                handleAlerta(response);
            } else {
                const clientsArray = response.dados.map(obj => {
                    const key = Object.keys(obj)[0];
                    const value = obj[key];
                    return { key, value };
                });
                setListaClientes(clientsArray);
            }
        }
        getClients();
    }, []);

    async function submitAdicionarCC(e) {
        e.preventDefault();
        setIsSubmit(true);

        const units = rows.map(row => ({
            cc: row.ccCol,
            address: row.addressCol,
            consumption: row.consumptionCol
        }));

        let tempDate = '';
        if (signDate) {
            setErrorSignDate(false);
            tempDate = signDate.toISOString().slice(0, 10);
        } else {
            setErrorSignDate(true);
            setIsSubmit(false);
            return;
        }

        const body = JSON.stringify({
            clientName: client,
            clientType,
            cpfCnpj,
            address,
            email,
            discount,
            signDate: tempDate,
            units
        });

        const response = await fetchFlask('generateAdditive', body);
        handleAlerta(response);

        if (response.status === 'success') {
            const link = document.createElement('a');
            link.href = response.message;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setMessage('Sua Proposta está sendo Baixada!!');
            setStatus(response.status);
            setOpenAlerta(true);

            setClient('');
            setClientType('');
            setCpfCnpj('');
            setAddress('');
            setEmail('');
            setDiscount('');
            setRows([]);
            setSignDate(null);
        }
        setIsSubmit(false);
    }

    function handleAlerta(response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    return (
        <Box className='mainBox'>
            <Grid container>
                <Grid item xs={10}>
                    <Paper elevation={3} sx={{ padding: 2 }}>
                        <Alerta
                            texto={message}
                            status={status}
                            open={openAlerta}
                            handleClose={() => setOpenAlerta(false)}
                        />
                        <Typography variant='h3'>
                            Aditivo
                        </Typography>
                        <form id='formAditivo' onSubmit={submitAdicionarCC}>
                            <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>

                                <Grid item xs={6}>
                                    <FormControl sx={{ display: 'flex', marginTop: '20px' }}>
                                        <InputLabel id='labelCliente'>Cliente</InputLabel>
                                        <Select
                                            id='selectCliente'
                                            labelId='labelCliente'
                                            label='Client'
                                            value={client}
                                            onChange={(e) => { setClient(e.target.value); }}
                                            required
                                        >
                                            {listaClientes.map((item) => (
                                                <MenuItem key={item.value} value={item.key}>{item.key}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={3}>
                                    <FormControl sx={{ display: 'flex', marginTop: '20px' }}>
                                        <InputLabel id='labelClientType'>Tipo</InputLabel>
                                        <Select
                                            id='selectClientType'
                                            labelId='labelClientType'
                                            label='clientType'
                                            value={clientType}
                                            onChange={(e) => setClientType(e.target.value)}
                                        >
                                            <MenuItem value={'pf'}>Pessoa Física</MenuItem>
                                            <MenuItem value={'pj'}>Pessoa Jurídica</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={3}>
                                    <FormControl sx={{ display: 'flex', marginTop: '20px' }}>
                                        <TextField
                                            required
                                            label='CPF ou CNPJ'
                                            value={cpfCnpj}
                                            inputProps={{ maxLength: 18 }}
                                            onChange={(e) => setCpfCnpj(maskCpfCnpj(e.target.value))}
                                        />
                                    </FormControl>
                                </Grid>
                                {clientType === 'pj' && (
                                    <>
                                        <Grid container spacing={2} sx={{ marginTop: '20px', alignItems: 'center', justifyContent: 'center' }}>

                                            <Grid item xs={6}>
                                                <FormControl sx={{ display: 'flex' }}>
                                                    <TextField
                                                        required
                                                        label='Nome do Representante'
                                                        value={representativeName}
                                                        onChange={(e) => setRepresentativeName(e.target.value)}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl sx={{ display: 'flex' }}>
                                                    <TextField
                                                        required
                                                        label='CPF do Representante'
                                                        value={representativeCpf}
                                                        inputProps={{ maxLength: 14 }}
                                                        onChange={(e) => setRepresentativeCpf(maskCpfCnpj(e.target.value))}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                    </>
                                )}
                                <Grid item xs={10}>
                                    <FormControl sx={{ display: 'flex', marginTop: '20px' }}>
                                        <TextField
                                            required
                                            label='Endereço'
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl sx={{ display: 'flex', marginTop: '20px' }}>
                                        <TextField
                                            required
                                            label='Email'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={3}>
                                    <FormControl sx={{ marginTop: '20px' }} >
                                        <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                                            <DatePicker id='data' label='Data de Assinatura'
                                                minDate={new Date('2023-01-02')}
                                                value={signDate}
                                                onChange={(value) => setSignDate(value)}
                                                slotProps={{
                                                    openPickerButton: {
                                                        color: 'primary'
                                                    }
                                                }}
                                            />
                                            {errorSignDate && (
                                                <Typography variant='caption' color='red'>
                                                    Por favor selecione uma data!
                                                </Typography>
                                            )}
                                        </LocalizationProvider>
                                    </FormControl >
                                </Grid>

                                <Grid item xs={2}>
                                    <FormControl sx={{ display: 'flex', marginTop: '20px' }}>
                                        <InputLabel id='labelDiscount'>Desconto</InputLabel>
                                        <Select
                                            id='selectDiscount'
                                            labelId='labelDiscount'
                                            label='discount'
                                            value={discount}
                                            onChange={(e) => setDiscount(e.target.value)}
                                        >
                                            <MenuItem value={'20'}>20%</MenuItem>
                                            <MenuItem value={'22'}>22%</MenuItem>
                                            <MenuItem value={'25'}>25%</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={10}>
                                    <Paper elevation={3} sx={{ padding: 2, margin: '20px' }}>
                                        <Grid container alignItems="center" justifyContent="space-between" marginBottom={2}>
                                            <Grid item>
                                                <Typography variant='h3' sx={{ marginBottom: '10px' }}>
                                                    Unidades
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Paper elevation={1} sx={{ backgroundColor: '#f0f1f383', marginTop: '10px', width: '100%' }}>
                                            <Box sx={{ height: 250 }}>
                                                <DataGrid
                                                    rows={rows}
                                                    columns={columns}
                                                    processRowUpdate={processRowUpdate}
                                                    onSelectionModelChange={handleRowSelection}
                                                    localeText={{ noRowsLabel: 'Sem Unidades' }}
                                                    hideFooter
                                                />
                                            </Box>
                                            {selectedRows.length > 0 && (
                                                <Tooltip title="Delete">
                                                    <IconButton onClick={() => deleteCC(selectedRows)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}

                                            <Grid container spacing={2} sx={{ padding: 2, justifyContent: 'center' }}>

                                                <Grid item>
                                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                                        <TextField
                                                            label='Conta Contrato'
                                                            value={cc}
                                                            onChange={(e) => setCC(e.target.value)}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            label='Consumo'
                                                            value={consumption}
                                                            onChange={(e) => setConsumption(e.target.value)}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            label='Endereço'
                                                            value={ccAddress}
                                                            onChange={(e) => setCCAddress(e.target.value)}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Button variant='contained' onClick={addUnits}>Adicionar Unidade</Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Paper>
                                </Grid>

                            </Grid>

                            <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                <Grid item>
                                    <FormControl sx={{ display: 'block', marginTop: '30px' }}>
                                        <SubmitButton isSubmit={isSubmit}>Gerar Aditivo</SubmitButton>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </form>
                    </Paper>
                </Grid>
            </Grid>

        </Box>
    );
};

export default GenerateAdditive;
