import { React } from 'react';

import { Route, Routes } from 'react-router-dom';

import AddAdditiveCC from '../pages/gestaoEnergia/addAdditiveCC';
import AlterarContato from '../pages/comercial/alterarContato';
import NotificarCliente from '../pages/comercial/notificarCliente';
import UserFakePortal from '../pages/comercial/userFakePortal';
import EmailVerificacao from '../pages/comercial/emailVerificacao';
import AdicionarCC from '../pages/gestaoEnergia/adicionarCC';
import ProrrogarBoleto from '../pages/financeiro/prorrogarBoleto';
import ProtectedRoute from '../components/protectedRoutes';

const RouteComercial = () => {
    return (
        <Routes>
            <Route element={<ProtectedRoute role='Comercial'/>}>
                <Route path='addAdditive' element={<AddAdditiveCC/>}/>
                <Route path='addClient' element={<addClients/>}/>
                <Route path='alterarContato' element={<AlterarContato/>}/>
                <Route path='notificarCliente' element={<NotificarCliente/>}/>
                <Route path='userFakePortal' element={<UserFakePortal/>}/>
                <Route path='adicionarCC' element={<AdicionarCC/>}/>
                <Route path='prorrogarBoleto' element={<ProrrogarBoleto/>}/>
                <Route path='emailVerificacao' element={<EmailVerificacao/>}/>
            </Route>
        </Routes>
    );
};

export default RouteComercial;
